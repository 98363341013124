import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import * as Pluto from 'pluto/packages/pluto_client/src';
import { Application } from '../../engine/Application';
import { load } from '../../engine/physics/AmmoLoader';
import { MainScene } from '../../domain/scenes/MainScene';
import SportActions from '../../containers/SportActions/SportActions';
import useCreateVoice from '../../hooks/audio/useCreateVoiceChatRoom';
import { getUrl } from '../../helpers/getUrl';
import TransportPlutoNeo from '../../engine/network/TransportPlutoNeo';
import NetworkManager from '../../engine/network/NetworkManager';
import useMicrophone from '../../hooks/audio/useMicrophone';
import UserModal from '../../containers/UserModal/UserModal';
import CharacterObject from '../../domain/network/objects/CharacterObject';
import NetworkObjectComponent from '../../engine/components/NetworkObject.component';
import useCreateTextChatRoom from '../../hooks/useCreateTextChatRoom';
import SceneInteraction from '../../Scene/SceneInteraction';
import getDolbyToken from '../../api/dolby/auth/getClientToken';
import { GenderEnum } from '../../enum/GenderEnum';
import { StuffStatus } from '../../domain/components/Stuff.component';

// todo: надо вынести инициализацию чатов на компоненту ниже

function SportView() {
  const [ammoIsLoaded, setAmmoIsLoaded] = useState(false);
  const [isStart, setIsStart] = useState<boolean>(true);

  const dcConfig = new Pluto.Dc_config('chat');
  const plutoConfig = useRef(new Pluto.Connection_config(
    { url: process.env.REACT_APP_PUBLIC_WSS_CONNECT || '' },
    dcConfig,
  ));
  plutoConfig.current.pc.iceServers = [{ urls: [process.env.REACT_APP_STUN_CONNECT || ''] }];

  const [transport, setTransport] = useState<TransportPlutoNeo>();
  const [userId, setUserId] = useState('');
  const [isClick, setIsClick] = useState<boolean>(false);

  const [token, setToken] = useState<string>('');
  const [refreshToken, setRefreshToken] = useState('');

  const [isConnect, setIsConnect] = useState<boolean>(false);
  const [App, setApp] = useState<null | Application>(null);
  const [userName, setUserName] = useState<string>('');
  const [urlRoomId, setUrlRoomId] = useState<string>('');
  const [roomId, setRoomId] = useState<string>('');
  const [genderName, setGenderName] = useState<GenderEnum>(GenderEnum.FEMALE);
  const [gender, setGender] = useState<GenderEnum>(GenderEnum.FEMALE);
  const [modalUserName, setModalUserName] = useState<string>('');
  const [isChat, setIsChat] = useState<boolean>(false);
  const [isLeft, setIsLeft] = useState<boolean>(false);

  const [sceneIntersections, setSceneInteraction] = useState<undefined | SceneInteraction>();

  useEffect(() => {
    setSceneInteraction(App?.getSystemOrFail(SceneInteraction));
  }, [App]);

  const audioRef = useRef<HTMLAudioElement>(null);

  const { rejoinGroupHandler, joinInfo, leaveGroupHandler, isSharedVoice } = useCreateVoice();

  const { getMicrophonePermission } = useMicrophone();

  useEffect(() => {
    if (!isStart) sceneIntersections?.blockScene(true);
    else sceneIntersections?.blockScene(false);
  }, [isStart]);

  useEffect(() => {
    setModalUserName(new URLSearchParams(window.location.search).get('username') || 'userTest');
  }, []);

  const getRefreshToken = useCallback(() => {
    getDolbyToken()
      .then((response) => {
        setToken(response.access_token);
        setRefreshToken(response.refresh_token);
        return response.refresh_token;
      });
  }, []);

  const refreshHandler = useCallback(() => {
    return getDolbyToken()
      .then((response) => {
        return response.refresh_token;
      });
  }, []);

  useEffect(() => {
    setUrlRoomId(new URLSearchParams(window.location.search).get('room') || '');
    setUserName(new URLSearchParams(window.location.search).get('username') || 'userTest');
  }, []);

  useEffect(() => getRefreshToken(), []);

  useEffect(() => {
    if (token && refreshToken) VoxeetSDK.initializeToken(token, refreshHandler);
  }, [token, refreshToken]);
  // todo: wtf?
  useEffect(() => {
    if (token && urlRoomId && userId && modalUserName && isClick && !joinInfo) {
      getMicrophonePermission().then((response) => {
        if (response) rejoinGroupHandler(urlRoomId, modalUserName, userId);
        setIsLeft(true);
        setIsClick(false);
      })
        .catch((e) => console.log(e));
    }
  }, [token, userId, urlRoomId, userName, isClick, joinInfo]);

  const {
    chatClient, initializeChat, channelClient, leaveChatHandler, isDisconnect, updateUser,
  } = useCreateTextChatRoom(); //

  useEffect(() => {
    if (roomId && urlRoomId !== roomId) {
      const newRelativePathQuery = getUrl(roomId);
      setUrlRoomId(roomId);
      window.history.pushState(null, '', newRelativePathQuery);
    }
  }, [roomId, urlRoomId]);

  const isAdmin = useMemo(() => {
    if (VoxeetSDK.conference.current !== null) return VoxeetSDK.conference.current?.isNew || false;
    return false;
  }, [joinInfo]);

  useEffect(() => {
    window.parent.addEventListener('rendering_disable', () => {
      App?.pauseLoop();
    });

    window.parent.addEventListener('rendering_enable', () => {
      App?.run();
    });
  }, [App]);

  const initApp = useCallback((plutoTransport: TransportPlutoNeo) => {
    const currentApp = new Application({ xrEnabled: true });
    const network = new NetworkManager(plutoTransport);
    currentApp.setNetworkManager(network);

    network.run();

    return network.syncUsersInRoom()
      .then(() => currentApp.sceneManager.loadScene(new MainScene()))
      .then(() => {
        currentApp.run();
        setApp(currentApp);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.web3devApp = currentApp;
        // eslint-disable-next-line global-require
        require('../../modules/js/script.js');
      });
  }, []);

  const chooseGenderHandler = useCallback((value: GenderEnum) => {
    setGenderName(value);
  }, [App, sceneIntersections]);

  const changeAvatarHandler = useCallback(() => {
    sceneIntersections?.throwStuff();
    setTimeout(() => {
      if (App !== null) {
        const { characterEntity } = App.sceneManager.currentScene as MainScene;
        if (!characterEntity) return;
        setIsStart(true);
        setIsClick(true);
        if (gender !== genderName) {
          const netObject = characterEntity.getComponentOrFail(NetworkObjectComponent).netObject as CharacterObject;
          netObject.changeGender(genderName);
          setGender(genderName);
          netObject.manager.sendOwnedObjects();
        }
        if (modalUserName !== userName) {
          setUserName(modalUserName);
          if (VoxeetSDK.session.participant) {
            setIsLeft(true);
            updateUser(userId, modalUserName);
            leaveGroupHandler();
          }
        }
      }
    }, 1200);
  }, [App, genderName, gender, userName, modalUserName, userId, sceneIntersections]);

  const connectRoom = useCallback(() => {
    if (!isConnect) {
      return Pluto.Client.create(plutoConfig.current).then((client) => {
        const plutoTransport = new TransportPlutoNeo(client, plutoConfig.current);
        setTransport(plutoTransport);
        setUserId(client.id);
        setIsConnect(true);
        return plutoTransport.createOrJointRoom(urlRoomId).then(({ roomId: createdRoomId }) => {
          setRoomId(createdRoomId);

          plutoTransport.listRoomConnections().then(({ connectionIds }) => {
            console.log('connectionIds', connectionIds);
          });

          return plutoTransport;
        });
      });
    }
  }, [isConnect, urlRoomId]);

  useEffect(() => {
    load.then((data) => {
      setAmmoIsLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (!ammoIsLoaded) return;

    connectRoom()?.then((plutoTransport) => initApp(plutoTransport));
  }, [ammoIsLoaded, connectRoom, initApp]);

  useEffect(() => {
    return () => {
      transport?.closeSession(process.env.NODE_ENV !== 'development');
      App?.destroy();
    };
  }, []);

  // FIXME: huck for foreground tab
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = 0.01;
    }
    const playAudio = () => {
      if (audioRef.current && audioRef.current.paused) {
        audioRef.current.play().catch(() => null);
      }
    };

    document.body.addEventListener('mousemove', playAudio);
    return () => {
      document.body.removeEventListener('mousemove', playAudio);
    };
  }, []);

  useEffect(() => {
    const loadedSceneHandler = () => {
      setIsStart(false);
    };

    window.addEventListener('scene_loaded', loadedSceneHandler);

    return () => window.removeEventListener('scene_loaded', loadedSceneHandler);
  }, []);

  useEffect(() => {
    if (!isChat && roomId && userId && modalUserName && isLeft) {
      setIsChat(true);
      initializeChat(modalUserName, userId, roomId);
    }
  }, [isChat, roomId, userId, modalUserName, isLeft]);

  const closeModalHandler = useCallback(() => {
    setIsStart(true);
    setIsClick(true);
    setGenderName(genderName || GenderEnum.FEMALE);
  }, [genderName]);

  if (!VoxeetSDK.session.participant && VoxeetSDK.conference.current === null) return <div />;

  return (
    <div
      className="App"
    >
      {App !== null && isLeft && <SportActions
        app={App}
        isSharedVoice={isSharedVoice}
        onChangeAvatar={() => setIsStart(false)}
        joinInfo={joinInfo}
        leaveChatHandler={leaveChatHandler}
        isAdmin={isAdmin}
        userView={modalUserName}
        roomId={roomId}
        chatClient={chatClient}
        channelClient={channelClient}
        isDisconnect={isDisconnect}
      />}
      {App?.renderer.xr.getSession() === null && <UserModal
        onClose={closeModalHandler}
        onClickGender={chooseGenderHandler}
        setNameHandler={setModalUserName}
        isOpen={!isStart}
        name={userName}
        onClick={changeAvatarHandler}
      />}
    </div>
  );
}

export default SportView;
