import { FC, useCallback, useMemo } from 'react';
import { Participant } from '@voxeet/voxeet-web-sdk/types/models/Participant';
import VoxeetSDK from '@voxeet/voxeet-web-sdk';
import IconButton from '../IconButton/IconButton';
import useAudio from "../../hooks/audio/useAudio";

type LocalToggleAudioButtonProps = {
  participant: Participant;
  onToggleAudio: (item: Participant) => void;
  disabled: boolean;
  joinInfo: string;
  size: 'Xs' | 'Md';
  isHasAudio: boolean;
};

const MircophoneToggleButton: FC<LocalToggleAudioButtonProps> = (props) => {
  const {
    size,
    joinInfo,
    participant,
    onToggleAudio = () => undefined,
    disabled = false,
    isHasAudio = false,
  } = props;

  const { isLocalAudioLoading } = useAudio();

  const iconName = useMemo(() => {
    if (isHasAudio) return 'Microphone';
    if (!isHasAudio) return 'MicrophoneSlash';
    return 'Microphone';
  }, [isHasAudio]);

  const toggleAudioHandler = useCallback(() => {
    onToggleAudio(participant);
  }, [participant]);

  return (
    <IconButton
      isLoading={isLocalAudioLoading}
      size={size}
      disabled={disabled}
      isActive={!joinInfo ? false : !isHasAudio}
      onCLick={toggleAudioHandler}
      icon={iconName}
    />
  );
};

export default MircophoneToggleButton;
