import EventEmitter from 'eventemitter3';
import { NetworkId, OnJoinedRoomPayload, OnLeftRoomPayload, OnMessagePayload, Transport } from './types';
import NetworkObject, { NetworkObjectLifeTimeTypes, NetworkObjectSerialized, NetworkObjectStatus } from './NetworkObject';
import { SyncVariable, SyncVariableSerialized } from './SyncVariable';
import TransformVariable from './variables/TransformVariable';
import TransportPlutoNeo from './TransportPlutoNeo';
import AnimatorVariable from './variables/AnimatorVariable';
import { WorkerMessage, WorkerMessageTypes } from './messages/WorkerTypes';
import MessagesPool, { Message, MessageData, MessageTransportType } from './messages/MessagesPool';
import MessagesWorker from './messages/MesagesWorker';
import ReceiveMessagesPool from './messages/ReceiveMessagesPool';
import SendMessagesPool from './messages/SendMessagesPool';

export enum MessageTypes {
  sendOwnedObjects = 'sendOwnedObjects',
  successReceiveNewObject = 'successReceiveNewObject',
  broadcastVariable = 'broadcastVariable',
  broadcastVariables = 'broadcastVariables',
  removeUser = 'removeUser',
  ping = 'Ping',
  broadcastMessagesBatch = 'broadcastMessagesBatch',
}

export type NetworkManagerEventTypes = {
  onReceiveObjects: () => void;
  onReceiveVariables: () => void;
};

export type UserData = {
  id: NetworkId;
  joinedTime: number;
};

// TODO: move all network from engine
export default class NetworkManager {
  public transport: TransportPlutoNeo;

  public receiveMessagesPool: ReceiveMessagesPool;

  public sendMessagesPool: SendMessagesPool;

  public enabled = true;

  public initialized = false;

  // public runHandler: NodeJS.Timer | null = null;

  protected _events: EventEmitter<NetworkManagerEventTypes> = new EventEmitter<NetworkManagerEventTypes>();

  public networkId: NetworkId;

  public prevNetworkId: NetworkId = '';

  public isRoomHost = false;

  public currentRoomId? = '';

  public messagesWorker: Worker | MessagesWorker;

  public lagTimeMS = 1000;

  public lastLagTime = 0;

  public lagsCount = 0;

  public lagsLimit = 6;

  // public debugRPS = 0;

  public variableTypes: { [key: string]: typeof SyncVariable<any> } = {
    [SyncVariable.type]: SyncVariable,
    [TransformVariable.type]: TransformVariable,
    [AnimatorVariable.type]: AnimatorVariable,
  };

  public objectsTypes: { [key: string]: typeof NetworkObject } = {
    [NetworkObject.type]: NetworkObject,
  };

  // TODO: need object User
  public onlineUsers: UserData[] = [];

  // TODO: create service objectCollection
  public objects: NetworkObject[] = [];

  constructor(transport: Transport) {
    // setInterval(() => {
    //   console.log('RPS:', this.debugRPS);
    //   this.debugRPS = 0;
    // }, 1000);

    this.transport = transport as TransportPlutoNeo;
    if (!transport.networkId) {
      console.error('No networkId');
    }
    this.receiveMessagesPool = new ReceiveMessagesPool();
    this.sendMessagesPool = new SendMessagesPool();
    // TODO: reconnect
    this.networkId = transport.networkId;
    this.currentRoomId = this.transport.client.room_id;
    this.setupNewUser(this.networkId);
    this.setupTransport();
    // this.syncUsersInRoom();

    this.messagesWorker = new Worker(new URL('./workers/Messages.worker', import.meta.url));
    // this.messagesWorker = new MessagesWorker();
    this.setupMessagesWorker();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.NM = this;
  }

  public reconnect() {
    if (!this.enabled) return Promise.resolve();
    console.warn('reconnect', this.networkId);
    this.enabled = false;
    const wait = (tm: number) => new Promise((resolve) => {
      setTimeout(() => resolve(null), tm);
    });
    const ownObjects = this.objects.filter((obj) => obj.isOwner() && !obj.isShared);
    this.prevNetworkId = this.networkId;
    return this.transport.reconnect()
      .then(() => {
        this.onlineUsers = this.onlineUsers.filter((user) => user.id === this.networkId);
        this.networkId = this.transport.networkId;
        console.log('new id:', this.networkId);
        ownObjects.forEach((obj) => obj.setOwner(this.networkId));
      })
      // .then(() => wait(300))
      .then(() => this.transport.createOrJointRoom(this.currentRoomId || null))
      .then(() => {
        this.currentRoomId = this.transport.client.room_id;
        this.enabled = true;
        // otherObjects.forEach((obj) => this.sendSuccessReceiveNewObject(obj));
        // this.messagesPool.flushMessages() ??????????????
        //   .forEach((message) => this.onReceive({ message, sender: this.networkId }));
      })
      .then(() => wait(1000))
      .then(() => this.syncUsersInRoom())
      .then(() => {
        this.objects.forEach((obj) => {
          if (!obj.isOwner() || obj.isShared) {
            this.sendSuccessReceiveNewObject(obj);
          }
        });
      })
      // .then(() => ownObjects.forEach((obj) => obj.reset()))
      .then(() => this.sendOwnedObjects());
  }

  // TODO: initialization state
  public finishInitialization() {
    this.initialized = true;
    this.sendOwnedObjects();
  }

  public reconnectToRoom() {
    if (!this.enabled) return Promise.resolve();
    this.enabled = false;
    return this.transport.leaveRoom().then(() => {
      if (this.currentRoomId) {
        return this.transport.joinRoom(this.currentRoomId);
      }
    });
  }

  public get events(): EventEmitter<NetworkManagerEventTypes> {
    return this._events;
  }

  public setupMessagesWorker() {
    if (this.messagesWorker instanceof Worker) {
      this.messagesWorker.onmessage = this.onWorkerMessage.bind(this);
    }
    if (this.messagesWorker instanceof MessagesWorker) {
      this.messagesWorker.events.on('onMessage', this.onWorkerMessage, this);
    }
  }

  public onWorkerMessage(e: MessageEvent<WorkerMessage> | WorkerMessage) {
    const message: WorkerMessage = e instanceof MessageEvent<WorkerMessage> ? e.data : e;
    switch (message.type) {
      // case WorkerMessageTypes.tick:
      //   this.sendData(MessageTypes.ping, {});
      //   break;
      case WorkerMessageTypes.processMessage:
        this.processMessage(message.data);
        break;
      case WorkerMessageTypes.broadcastMessagesBatch:
        this.broadcastMessagesBatch(message.data);
        break;
      default:
    }
  }

  public run() {
    this.messagesWorker.postMessage({ type: WorkerMessageTypes.startWorker });
  }

  public stop() {
    this.messagesWorker.postMessage({ type: WorkerMessageTypes.stopWorker });
    this.messagesWorker.terminate();
  }

  public buildNetObject<Type extends NetworkObject>(type: string, build: ((obj: Type) => void) | undefined = undefined): Type {
    const obj = new this.objectsTypes[type](this).initialize() as Type;
    if (build) {
      build(obj);
    }
    this.objects.push(obj);
    // this.sendOwnedObjects();
    return obj;
  }

  public buildSharedObject<Type extends NetworkObject>(
    type: string,
    code: string,
    build: ((obj: Type) => void) | undefined = undefined,
  ): Type {
    const netObj = this.objects.find((obj) => obj.isShared && obj.code === code);
    if (netObj) {
      return netObj as Type;
    }
    return this.buildNetObject<Type>(type, (obj) => {
      obj.code = code;
      obj.lifeTimeType = NetworkObjectLifeTimeTypes.Shared;
      if (build) build(obj);
    });
  }

  public getObjectByCode<Type extends NetworkObject = NetworkObject>(code: string): Type {
    return this.objects.find((obj) => obj.code === code) as Type;
  }

  public receiveVariable(data: SyncVariableSerialized<any>, sendTime: number, receiveTime: number) {
    const object = this.objects.find((obj) => (obj.uuid === data.netObjectUuid));
    // TODO: think about it
    if (!object) return;
    let variable = object.getVariableByUuid(data.uuid);
    // crate new variable
    if (!variable) {
      variable = new this.variableTypes[data.type](data.name);
      object.addVariable<typeof variable.value>(variable);
    }
    variable.deserialize(data);
    variable.saveValueFromNetwork(data, sendTime, receiveTime);
    variable.setNeedUpdateFromNetwork();
    this.events.emit('onReceiveVariables');
  }

  public receiveNetObject(data: NetworkObjectSerialized) {
    let object = this.objects.find((obj) => obj.uuid === data.uuid);
    // new object
    let isNewObject = false;
    if (!object && data.status !== NetworkObjectStatus.Removed) {
      object = new this.objectsTypes[data.type](this);
      this.objects.push(object);
      isNewObject = true;
    }
    // update object
    if (object) {
      object.deserialize(data);
    }
    // remove object
    if (object && data.status === NetworkObjectStatus.Removed) {
      this.objects = this.objects.filter((obj) => obj.uuid !== data.uuid);
      object.remove();
    }

    // if (isNewObject && object) {
    //   this.sendSuccessReceiveNewObject(object);
    // }
    if (isNewObject && object) {
      this.sendSuccessReceiveNewObject(object);
    }
  }

  public receiveSuccessReceiveNewObject({ netObjectUid, clientId }: { netObjectUid: string; clientId: string }) {
    const object = this.objects.find((obj) => obj.uuid === netObjectUid);
    if (!object || !object.isOwner()) return;
    // TODO: think about required
    this.broadcastVariables(object.variables, [clientId], true);
  }

  public setupTransport() {
    this.transport.events.on('onJoinedRoom', this.onJoinedRoom, this);
    this.transport.events.on('onMessage', this.onReceive, this);
    this.transport.events.on('onData', this.onReceive, this);
    this.transport.events.on('onLeftRoom', this.onLeftRoom, this);
  }

  // one session -> many rooms ?
  public onJoinedRoom({ roomId, clientId }: OnJoinedRoomPayload) {
    console.warn('onJoinedRoom', clientId, this.networkId);
    // do not process yourself
    if (clientId === this.networkId || clientId === this.prevNetworkId) {
      return;
    }
    if (this.currentRoomId && this.currentRoomId !== roomId) {
      // TODO: not our room, strange
      console.warn('wrong room');
      return;
    }
    this.currentRoomId = this.currentRoomId || roomId;
    this.syncUsersInRoom().then((newClients) => {
      if (newClients && newClients.length > 0) this.sendOwnedObjects(newClients);
    });
  }

  public syncUsersInRoom() {
    if (!this.currentRoomId) return Promise.resolve([]);
    return this.transport.listRoomConnections(this.currentRoomId).then(({ roomId, connectionIds }) => {
      if (this.currentRoomId !== roomId) return;
      if (connectionIds.length === 1 && this.networkId === connectionIds[0]) {
        this.isRoomHost = true;
        console.warn('host');
      }
      const newUsers: NetworkId[] = [];
      connectionIds.forEach((userId) => {
        if (!this.isUserOnline(userId)) {
          this.setupNewUser(userId);
          newUsers.push(userId);
        }
      });
      this.onlineUsers.forEach((user) => {
        if (connectionIds.indexOf(user.id) < 0) {
          this.removeUser(user.id);
        }
      });
      return newUsers;
    });
  }

  public onLeftRoom({ roomId, connectionId }: OnLeftRoomPayload) {
    if (this.currentRoomId !== roomId) return;
    if (this.networkId === connectionId || this.prevNetworkId === connectionId) {
      // TODO: reconnect
    }
    this.removeUser(connectionId);
  }

  public removeUser(userId: NetworkId) {
    console.warn('removeUser', userId);
    // do not process yourself
    if (userId === this.networkId || userId === this.prevNetworkId) {
      return;
    }
    // TODO: clear variables
    this.onlineUsers = this.onlineUsers.filter((user) => user.id !== userId);
    this.removeObjectsByOwner(userId);
    // this.sendOwnedObjectsVariables();
  }

  public removeObjectsByOwner(ownerId: NetworkId) {
    // TODO: reset owner in shared object (by time or by server)
    // TODO: network objects and variables need timing
    const newOwnerId = this.onlineUsers.map((user) => user.id).sort()[0];
    // console.log('newOwnerId', newOwnerId, this.onlineUsers);
    // console.log('netId', this.networkId);
    if (newOwnerId) {
      this.objects
        .filter((obj) => obj.isShared && !this.isUserOnline(obj.ownerId))
        .forEach((obj) => {
          obj.setOwner(newOwnerId);
          // obj.reset();
        });
    }

    this.objects.filter((obj) => !obj.isLife(ownerId)).forEach((obj) => obj.remove());
    this.objects = this.objects.filter((obj) => obj.isLife(ownerId));
    // this.sendOwnedObjects();
  }

  public isUserOnline(userId: NetworkId): boolean {
    return !!this.onlineUsers.find((user) => user.id === userId);
  }

  public setupNewUser(userId: NetworkId) {
    this.onlineUsers.push({
      id: userId,
      joinedTime: Math.floor(Date.now() / 1000),
    });
  }

  public sendOwnedObjects(clients: NetworkId[] = []) {
    const payload = this.objects.filter((obj) => obj.isOwner()).map((obj) => (
      {
        toUsers: clients,
        object: obj.serialize(),
      }
    ));
    if (payload.length > 0) {
      this.sendMessage(MessageTypes.sendOwnedObjects, payload, true, clients);
    }
  }

  public sendOwnedObjectsVariables() {
    this.objects.filter((obj) => obj.isOwner())
      .forEach((obj) => this.broadcastVariables(obj.variables));
  }

  public sendSuccessReceiveNewObject(object: NetworkObject) {
    const payload = {
      netObjectUid: object.uuid,
      clientId: this.networkId,
    };
    this.sendMessage(MessageTypes.successReceiveNewObject, payload, true, [object.ownerId]);
  }

  public broadcastVariable<T>(variable: SyncVariable<T>) {
    if (!this.initialized) return;
    if (variable.required) {
      return this.sendMessage(MessageTypes.broadcastVariable, {
        variable: variable.serialize(),
      }, variable.required);
    }
    return this.sendData(MessageTypes.broadcastVariable, {
      variable: variable.serialize(),
    }, variable.required);
  }

  public broadcastVariables<T>(variables: SyncVariable<T>[], clients: NetworkId[] = [], required?: boolean) {
    if (!this.initialized) return;
    const requiredMessage = typeof required !== 'undefined' ? required : variables.some((vr) => vr.required);
    if (requiredMessage) {
      this.sendMessage(MessageTypes.broadcastVariables, {
        variables: variables.map((variable) => variable.serialize()),
      }, requiredMessage, clients);
    }
    return this.sendData(MessageTypes.broadcastVariables, {
      variables: variables.map((variable) => variable.serialize()),
    }, requiredMessage, clients);
  }

  public sendRemoveUser(userId: NetworkId) {
    this.sendData(MessageTypes.removeUser, {
      userId,
    }, true);
  }

  public receiveRemoveUser(userId: NetworkId) {
    if (this.networkId === userId) {
      this.transport.closeSession(false).then(() => {
        // this.objects.forEach((obj) => obj.remove());
      });
      this.objects.forEach((obj) => obj.remove());
      this.objects = [];
    }
  }

  public send({
    type,
    payload,
    transportType,
    required = false,
    clients = [],
  }: { type: string; payload: any; required: boolean; clients: NetworkId[]; transportType: MessageTransportType }) {
    if (!this.enabled) return;

    const message = MessagesPool.createMessage({
      type,
      required,
      payload,
      transportType,
      clients,
    });

    // TODO: think about it
    if (type === MessageTypes.broadcastVariable) {
      message.variableUid = payload.variable.uuid;
    }

    const workerMessage = { ...message };
    workerMessage.data = { type: message.data.type };

    this.sendMessagesPool.addMessage(message);
    this.messagesWorker.postMessage({ type: WorkerMessageTypes.sendMessage, data: workerMessage });
  }

  public sendMessage(type: string, payload: any, required = false, clients: NetworkId[] = []) {
    return this.send({
      type, payload, required, clients, transportType: MessageTransportType.WS,
    });
  }

  public sendData(type: string, payload: any, required = false, clients: NetworkId[] = []) {
    return this.send({
      type, payload, required, clients, transportType: MessageTransportType.RTC,
    });
  }

  public reconnectIfLags(message: Message) {
    // TODO: serverSendTime
    // console.log('onReceive (local - local)', message.receiveTime - message.sendTime);
    // console.log('onReceive (local - server)', message.receiveTime - message.serverSendTime);
    const lagTime = message.receiveTime - message.serverSendTime;
    if (lagTime > this.lagTimeMS) console.warn('lags', lagTime);
    if (lagTime > this.lagTimeMS
      && (lagTime > this.lastLagTime || lagTime > 3 * this.lagTimeMS)
      && this.objects.length > 0 && this.objects.every((obj) => obj.isInitialized)
    ) {
      this.lagsCount += 1;
      if (this.lagsCount >= this.lagsLimit) {
        this.reconnect().then(() => {
          console.log('reconnect complete');
          this.lagsCount = 0;
        });
        return;
      }
    }
    this.lastLagTime = lagTime;
  }

  public onReceive({ message, sender, serverSendTime }: OnMessagePayload) {
    message.serverSendTime = serverSendTime;
    // if (!this.enabled) {
    //   this.receiveMessagesPool.saveMessage(message);
    //   return;
    // }
    if (!message.data && !(<Message>message).data.type) return;
    // save message with payload in main thread
    this.receiveMessagesPool.receiveMessage(message);
    this.reconnectIfLags(message);
    const workerMessage = { ...message };
    workerMessage.data = { type: message.data.type };
    this.messagesWorker.postMessage({ type: WorkerMessageTypes.receiveMessage, data: workerMessage });
  }

  public processMessage(message: Message) {
    let { data } = message;
    if (!data.payload) {
      const messageFromPool = this.receiveMessagesPool.getByUid(message.uid);
      data = (messageFromPool || { data: null }).data as MessageData;
    }
    if (!data || !data.payload) return;

    this.receiveMessagesPool.removeByUid(message.uid);

    switch (data.type) {
      case MessageTypes.sendOwnedObjects:
        data.payload.forEach(({ object, toUser } : { object: NetworkObjectSerialized; toUser: NetworkId }) => {
          this.receiveNetObject(object);
        });
        // console.log('onReceiveObjects');
        // console.log([...this.objects]);
        this.events.emit('onReceiveObjects');
        break;
      case MessageTypes.successReceiveNewObject:
        this.receiveSuccessReceiveNewObject(data.payload);
        break;
      case MessageTypes.broadcastVariable:
        this.receiveVariable(
          data.payload.variable,
          message.serverSendTime + (message.timeDiff || 0),
          message.receiveTime,
        );
        break;
      case MessageTypes.broadcastVariables:
        data.payload.variables
          .forEach(
            (variable: SyncVariableSerialized<any>) => this.receiveVariable(
              variable,
              message.serverSendTime + (message.timeDiff || 0),
              message.receiveTime,
            ),
          );
        break;
      case MessageTypes.removeUser:
        this.receiveRemoveUser(data.payload.userId);
        break;
      case MessageTypes.broadcastMessagesBatch:
        data.payload.forEach((ms: Message) => {
          ms.serverSendTime = message.serverSendTime;
          this.onReceive({ message: ms, serverSendTime: message.serverSendTime, sender: '' });
        });
        break;
      default:
        console.warn(`Network: unknown message type ${data.type}`);
    }
  }

  // TODO: optimize messages size: duplicate information
  public broadcastMessagesBatch(messages: Message[]) {
    const messagesWithPayload: Message[] = [];
    messages.forEach((ms) => {
      const payloadMessage = this.sendMessagesPool.getByUid(ms.uid);
      this.sendMessagesPool.removeByUid(ms.uid);
      if (payloadMessage) messagesWithPayload.push(payloadMessage);
    });
    Object.values(MessageTransportType).forEach((transportType) => {
      const payload = messagesWithPayload.filter((pl) => pl.transportType === transportType);
      if (payload.length === 0) return;
      // split by clients
      // TODO: optimize & rewrite
      const clientMessages: Record<NetworkId, Message[]> = { '': [] };
      payload.forEach((ms) => {
        if (!ms.clients.length) {
          clientMessages[''].push(ms);
        } else {
          ms.clients.forEach((client) => {
            if (!clientMessages[client]) clientMessages[client] = [];
            clientMessages[client].push(ms);
          });
        }
      });
      Object.keys(clientMessages).forEach((client) => {
        if (!clientMessages[client] || clientMessages[client].length === 0) return;
        const message = MessagesPool.createMessage({
          type: MessageTypes.broadcastMessagesBatch,
          required: true, /// ?????????
          payload: clientMessages[client],
          transportType,
          clients: client ? [client] : [], /// ???????
        });
        this.sendByTransport(message, transportType, client ? [client] : []);
      });
    });
  }

  public sendByTransport(message: Message, transportType: MessageTransportType, clients: NetworkId[]) {
    // this.debugRPS += 1;
    if (transportType === MessageTransportType.WS) {
      return clients.length > 0 ? this.transport.sendMessageTo(clients, message) : this.transport.sendMessageInRoom(message);
    }
    if (transportType === MessageTransportType.RTC) {
      return clients.length > 0 ? this.transport.sendDataTo(clients, message) : this.transport.sendDataInRoom(message);
    }
  }
}
