import { VRButton } from 'three/examples/jsm/webxr/VRButton';
import { Spector } from 'spectorjs';
import * as THREE from 'three';
import { EntityManager } from './EntityManager';
import { ComponentManager } from './ComponentManager';
import { System } from './System';
import { SceneManager } from './scene/SceneManager';
import NetworkManager from './network/NetworkManager';

export type ApplicationOptions = {
  xrEnabled: boolean;
};

export class Application {
  public entityManager: EntityManager;

  public componentManager: ComponentManager;

  public networkManager: NetworkManager | null = null;

  public camera: THREE.PerspectiveCamera | null = null;

  public renderer: THREE.WebGLRenderer = new THREE.WebGLRenderer({
    // antialias: true,
    antialias: true,
    powerPreference: 'high-performance',
    alpha: true,
  });

  public clock: THREE.Clock = new THREE.Clock();

  public systems: System[] = [];

  public sceneManager: SceneManager;

  protected xrEnabled: boolean;

  constructor(options: ApplicationOptions) {
    this.xrEnabled = options.xrEnabled;
    this.entityManager = new EntityManager({ app: this });
    this.componentManager = new ComponentManager();
    this.sceneManager = new SceneManager({ app: this });
    this.setupXRLiveReload();
    this.setupRenderer();
  }

  public setNetworkManager(manager: NetworkManager) {
    this.networkManager = manager;
  }

  public get targetFramerate(): number {
    return this.renderer.xr.getSession()?.frameRate || 60;
  }

  public get isInVR(): boolean {
    return this.renderer.xr.isPresenting;
  }

  public run(): void {
    // this.enableSpector();
    this.clock.start();
    this.renderer.setAnimationLoop(() => {
      this.render();
    });
  }

  public pauseLoop(): void {
    this.renderer.setAnimationLoop(null);
  }

  public render() {
    const delta = this.clock.getDelta();

    if (this.camera) this.renderer.xr.updateCamera(this.camera);

    this.systems.forEach((system) => system.onUpdate(delta));

    this.sceneManager.currentScene?.render(this, delta);

    this.systems.forEach((system) => system.onAfterRender(delta));
  }

  public destroy(): void {
    this.networkManager?.stop();
    this.renderer.dispose();
    this.renderer.domElement.remove();
    this.systems.forEach((system) => system.destroy());
  }

  public getSystem<T extends typeof System>(SystemType: T): InstanceType<T> | undefined {
    const system = this.systems.find((_system) => _system instanceof SystemType);

    return system ? system as InstanceType<T> : system;
  }

  public getSystemOrFail<T extends typeof System>(SystemType: T): InstanceType<T> {
    const system = this.getSystem(SystemType);

    if (!system) throw new Error(`System ${SystemType.code} not found`);

    return system;
  }

  public destroyAllSystems(): void {
    this.systems.forEach((system) => system.destroy());
    this.systems = [];
  }

  protected enableFramerateLimit(limit: number): void {
    // REFACTOR!!!
    this.renderer.xr.addEventListener('sessionstart', () => {
      const session = this.renderer.xr.getSession();

      if (!session) return;

      if (!session.supportedFrameRates?.includes(limit)) return;

      session.onframeratechange = (test) => {
        if (test.session.frameRate !== limit) {
          session.updateTargetFrameRate(limit).catch(() => undefined);
        }
      };

      session.updateTargetFrameRate(limit).catch(() => undefined);
    });
  }

  protected enableSpector(): void {
    const spector = new Spector();
    spector.spyCanvases();
    spector.displayUI();
  }

  protected setupXRLiveReload(): void {
    const hot = window?.module?.hot || import.meta.webpackHot;
    // temporary solution for fast develop in oculus quest2 1
    if (hot?.addStatusHandler) {
      hot.addStatusHandler((e) => {
        if (e === 'check') {
          if (this.renderer) {
            this.renderer.xr.getSession()?.end();
          }
          window.location.reload();
        }
      });
    }
  }

  protected setupRenderer(): void {
    // this.renderer.domElement.style.userSelect = 'none';
    this.renderer.setClearColor(0xfffff);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    // this.renderer.localClippingEnabled = true;
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    // this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = 1;
    this.renderer.domElement.oncontextmenu = () => false;

    if (this.xrEnabled) {
      this.renderer.xr.enabled = true;
      if (window.innerWidth > 970) document.body.appendChild(VRButton.createButton(this.renderer));
    }

    this.renderer.xr.setReferenceSpaceType('local');
    // this.renderer.xr.setFramebufferScaleFactor(2); // decrease performance
    // this.renderer.xr.setFoveation(0); // decrease performance
    document.body.appendChild(this.renderer.domElement);
    // this.enableFramerateLimit(72); // think about it
    // this.enableSpector(); // think about it
  }
}
